import React from 'react';
import * as Styled from './slpCRPImageWithTextStyles';

const SlpCRPImageWithText = ({ sectionData }) => {
  return (
    <Styled.ImageStyle $background={sectionData?.image?.file?.url}>
      {sectionData?.headerWithText && (
        <Styled.Heading>
          {sectionData?.headerWithText?.headerText}
        </Styled.Heading>
      )}
    </Styled.ImageStyle>
  );
};

export default SlpCRPImageWithText;
